import { GoogleLogin } from '@react-oauth/google'
import React from 'react'
import MyGoogleLogin from '../MyGoogleLogin'



function MainGoogle() {
  return (
    <div><MyGoogleLogin/>  </div>
  )
  
}

export default MainGoogle