import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



const MyGoogleLogin = () => {
    
    const navigate=useNavigate();
    const CLIENT_ID = '656562762328-bsb5jfio9ncmba26gr73ou0h9dkbsui9.apps.googleusercontent.com';

    const handleCredentialResponse = (response) => {
        console.log('Encoded JWT ID token: ' + response.credential);



        // Send the response.credential to your backend for verification if needed.
        const decodedToken = decodeJwt(response.credential);
        sessionStorage.setItem('google_token', response.credential);

        console.log('Decoded token:', decodedToken);
        navigate("/");
    };

    useEffect(() => {
        // Initialize Google Sign-In
        window.google.accounts.id.initialize({
            client_id: CLIENT_ID,
            callback: handleCredentialResponse,
        });    

        // Render the Google Sign-In button
        window.google.accounts.id.renderButton(
            document.getElementById('google-signin-button'),
            { theme: 'outline', size: 'large' } // Customize button appearance
        );

        // Optionally, auto-prompt the user
        window.google.accounts.id.prompt();
    }, [handleCredentialResponse]);

    

    const decodeJwt = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`)
                .join('')
        );
        return JSON.parse(jsonPayload);
    };

    return (
        <div>
            <h1>Google Login</h1>
            <div id="google-signin-button"></div>
        </div>
    );
};

export default MyGoogleLogin;
