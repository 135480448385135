
import './App.css';

import LoginPage from './LoginPage';
import Logout from './Logout';
import MainPage from './MainPage';
import About from './components/About';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // Import Routes and Route
import ShareAlarms from './components/ShareAlarms';
import MainGoogle from './components/MainGoogle';
function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<MainPage/>} />
      <Route path="/login" element={<LoginPage/>} />      
      <Route path="/logout" element={<Logout/>} />      
      <Route path="/about" element={<About/>} />      
      <Route path="/sharealarms" element={<ShareAlarms/>} />  
      <Route path="/loginGoogle" element={<MainGoogle/>} />  
      
    </Routes>
  </Router>
  
  );
}

export default App;
