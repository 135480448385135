import React, { useEffect } from 'react';

import { verifyToken } from '../authUtils';
import { useNavigate } from 'react-router-dom';
import Menu from '../Menu';
export const About = () => {
  const navigate=useNavigate();
  useEffect(() => {
    // Check token validity on component mount
    const token = sessionStorage.getItem('google_token'); // Or localStorage
    if(token==null){
      navigate("/loginGoogle") ;
      return;
    }
    console.log("token:"+token);

    
    if (token==null || !token || !verifyToken(token)) {
      
      console.log('Invalid or missing token');
      navigate("/loginGoogle") ;
  }
 
}, [navigate]); // Empty dependency array ensures this runs only on initial render


  return (
    
    <div>
      <div><Menu/></div>
    <div>About</div>
    </div>
  )
}


export default About;