import React, { Component } from 'react';

class Logout extends Component {
    componentDidMount() {
        // Clear token from sessionStorage (or localStorage)
        sessionStorage.removeItem('google_token');

        // Redirect to the login page
        window.location.href = '/loginGoogle';
    }

    render() {
        return (
            <div>
                <h1>Logging out...</h1>
            </div>
        );
    }
}

export default Logout;
