import React from 'react'
import { useEffect } from 'react';
import Menu from './Menu'
import { useNavigate } from 'react-router-dom';
import { verifyToken } from './authUtils';

function MainPage() {
  const navigate=useNavigate();
  useEffect(() => {
    // Check token validity on component mount
    const token = sessionStorage.getItem('google_token'); // Or localStorage
    if(token==null){
      navigate("/loginGoogle") ;
      return;
    }
    console.log("token:"+token);

    
    if (token==null || !token || !verifyToken(token)) {
      
      console.log('Invalid or missing token');
      navigate("/loginGoogle") ;
  }
 
}, [navigate]); // Empty dependency array ensures this runs only on initial render
  return (
    <div><Menu/></div>
  )
}

export default MainPage