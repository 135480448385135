
import {jwtDecode} from 'jwt-decode';

/**
 * Verifies if a JWT token is valid by checking its expiration.
 * @param {string} token - The JWT token to verify.
 * @returns {boolean} - True if the token is valid, false otherwise.
 */
export const verifyToken = (token) => {
    if(token == null ) return false;
    if (!token) return false;

    try {
        const decodedToken = jwtDecode(token);
        const email=decodedToken.email;
        console.log( email ) ;
        if (email !== "petretamasan@gmail.com"){
            return false;
        }
        const currentTime = Date.now() / 1000; // Convert to seconds
        return decodedToken.exp > currentTime;
    } catch (error) {
        console.error('Invalid token:', error);
        return false;
    }
};
