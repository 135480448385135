// LoginPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import './LoginPage.css';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  
  const handleLogin = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!username || !password) {
      setError('Please fill out all fields.');
      return;
    }

    setError('');
    setLoading(true);

    try {
      // Make API call
     

      const response = await fetch('https://app64.heliohost.us/api/checkAuth.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Headers': 'X-Requested-With'

        },
        body: JSON.stringify({ username, password }),
      });




      const data = await response.json();

      if (response.ok) {
        // Handle successful login, e.g., redirect to dashboard
        console.log('Login successful:', data);
        //localStorage.setItem('isLoggedIn', 'true'); // Store login state
        setItemWithExpiry("isLoggedIn","true",60*2);
        navigate("/");

      } else {
        // Handle login error
        setError(data.message || 'Invalid credentials');
      }
    } catch (err) {
      console.error('Login error:', err);
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <h2 className="login-title">Login</h2>
      <form onSubmit={handleLogin} className="login-form">
        <div className="input-group">
          <label>Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="login-input"
            required
          />
        </div>
        <div className="input-group">
          <label>Password</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
            required
          />
        </div>
        {error && <p className="error-message">{error}</p>}
        <button type="submit" className="login-button" disabled={loading}>
          {loading ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
}

function setItemWithExpiry(key, value, expiryInMinutes) {
  const now = new Date();

  // Create an item with both the value and expiration time
  const item = {
    value: value,
    expiry: now.getTime() + expiryInMinutes * 60 * 1000, // Convert minutes to milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
  
}

export default LoginPage;
